import { useContext, useState } from "react";
import styles from "./SingleGameList.module.css";
import { useNavigate } from "react-router-dom";
import PlayGameModal from "../../HomeComponents/PlayerGameModal/PlayerGameModal";
import UserContext from "../../../helpers/Context/user-context";
import LazyGameImage from "./LazyGameImage";
import SvenusImg from '../../../assets/HomeImages/Section1/svenus-img.webp'

const SingleGameList = ({ game, gameLink }) => {
  // console.log(gameLink);
  const [playGameState, setPlayGameState] = useState(false);
  const [singleGameModal, setSingleGameModal] = useState();

  const navigate = useNavigate();
  const ctx = useContext(UserContext);
  // Open Game Modal
  const openGameModal = async (game) => {
    if (!ctx.user) {
      navigate("/login");
    } else {
      setPlayGameState(true);
      setSingleGameModal(game);
    }
  };
  return (
    <div className={styles.sectionContent}>
      {
        <div className={styles.imageContainer}>
          {game.icon_square ? (
            <div
              key={game.game_id}
              style={{ height: '420px' }}
            >
              <img className={styles.dagaTitleImage} src={SvenusImg} alt="SvenusImg" />
              <button
                onClick={() => openGameModal(game)}
                className={styles.overlayButton}
              >
                Chơi ngay
              </button>
            </div>
          ) : game && (
            <div className={styles.noImage}>
              <h4>Không tìm thấy trò chơi nào</h4>
            </div>
          )}
        </div>
      }
      {playGameState && (
        <PlayGameModal
          playGameState={playGameState}
          setPlayGameState={setPlayGameState}
          singleGameModal={singleGameModal}
          setSingleGameModal={setSingleGameModal}
        />
      )}
    </div>
  );
};

export default SingleGameList;
