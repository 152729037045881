import styles from "./AnnouncementPopup.module.css";
import { Modal } from "@mui/material";
import { motion } from "framer-motion";
import { AiOutlineClose } from "react-icons/ai";


const AnnouncementPopup = ({ show, hideModal, announcement }) => {
  const popupVariants = {
    hidden: {
      scale: 0.5,
      opacity: 0,
    },
    visible: {
      scale: 1,
      opacity: 1,
      transition: {
        type: "spring", // For a spring-like effect
        stiffness: 100, // Adjust stiffness for more or less "bounce"
        damping: 10, // Adjust damping for more or less resistance
        duration: 0.5, // Duration of the animation
      },
    },
  };

  const handleImageClick = async (imageUrl) => {
    if (announcement.is_redirect != null) {
      window.open(imageUrl, "_blank");
    }
  };

  return (
    <Modal className={styles.modalOverlay} open={show} onClose={hideModal}>
      <motion.div
        className={styles.modalWrapper}
        variants={popupVariants}
        initial="hidden"
        animate={show ? "visible" : "hidden"}
      >
        <div className={styles.title}>
          <span>Thông báo</span>
          <AiOutlineClose
            className={styles.icon}
            size={30}
            onClick={hideModal}
          />
        </div>

        <div className={styles.contentWrapper}>
          <div className={styles.leftSection}>
            <div className={styles.leftContent}>
              <div className={styles.round}></div>
              <span>LINK GIẢ MẠO</span>
            </div>
          </div>

          <div className={styles.rightSection}>
            {announcement?.is_redirect === 1 && (
              <img
                src={announcement?.image_url}
                alt="Announcement"
                onClick={() => handleImageClick(announcement.url)}
              />
            )}
            {announcement?.content && (
              <div className={styles.rightContent}>
                <h3>{announcement?.title}</h3>
                <p dangerouslySetInnerHTML={{ __html: announcement?.content }} />
              </div>
            )}
          </div>
        </div>
      </motion.div>
    </Modal>
  );
};

export default AnnouncementPopup;
