import getAxiosInstance from "./BaseUrl";

// Get Banner Images
export const APIBannerImages = async () => {
  const BaseUrl = await getAxiosInstance();

  try {
    const response = await BaseUrl.get('image-slider/list?device=desktop')
    if (response.data) {
      return response.data
    }
  } catch (error) {
    console.error('Error fetching banner images: ', error)
  }
  return null;
}
