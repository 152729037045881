import React, { useState, useEffect } from "react";
import './ForgotPassword.css'
import { generateOTP, verifyOTP, forgotPassword } from "../../helpers/APIs/ForgotPasswordAPI";
import { useNavigate } from "react-router-dom";
import { Divider, Button } from "@mui/material";
import { FaPhoneAlt } from "react-icons/fa";
import CheckDecagram from '../../assets/check-decagram.svg';

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [phone, setPhone] = useState("");
  const [otp, setOtp] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [sentOTP, setSentOTP] = useState(false);
  const [expiredOTP, setExpiredOTP] = useState(false);
  const [timer, setTimer] = useState(60);
  const [isOTPcorrect, setIsOTPCorrect] = useState(false);
  const [resetSuccess, setResetSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [token, setToken] = useState("");
  const [buttonLabel, setButtonLabel] = useState("Gửi OTP");
  const [instruction, setInstruction] = useState("Vui lòng nhập tài khoản Zalo của bạn để nhận mã xác minh OTP")

  useEffect(() => {
    let interval;
    if (sentOTP && timer > 0) {
      interval = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    } else if (timer === 0) {
      setExpiredOTP(true);
      clearInterval(interval);
    }
    return () => clearInterval(interval); // Clear interval on unmount
  }, [sentOTP, timer]);

  const formatTime = () => {
    const minutes = Math.floor(timer / 60);
    const seconds = timer % 60;
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  const disableForgotPasswordButton = () => {
    if (sentOTP) {
      if (isOTPcorrect) {
        return newPassword.length < 6;
      } else {
        return expiredOTP || otp.length < 4
      }
    } else {
      return phone.length < 10;
    }
  }

  const buttonStyles = {
    background: phone.length < 10 ? '#737797' : 'linear-gradient(180deg, #06BBF7 0%, #17346C 100%)',
    border: phone.length < 10 ? 'none' : '1px solid #06B9F3',
    cursor: phone.length < 10 ? 'not-allowed' : 'pointer',
    color: phone.length < 10 ? '#2C386E' : '#fff',
    borderRadius: '8px',
    height: '50px',
    fontSize: '20px',
    fontWeight: 600,
    textTransform: 'none',
    marginTop: '-15px',
    pointerEvents: 'all'
  }

  const handleSendOTP = async () => {
    const result = await generateOTP(phone);
    console.log('handleSendOTP Result: ', result);
    if (result.status) {
      setSentOTP(true);
      setTimer(60);
      setExpiredOTP(false);
      setButtonLabel("Xác minh");
      setInstruction("Vui lòng nhập OTP");
    } else {
      setMessage(result.message);
      console.error(result.message);
    }
  };

  const handleVerifyOTP = async () => {
    const result = await verifyOTP(phone, otp);
    console.log('handleVerifyOTP Result: ', result);
    if (result.status) {
      setIsOTPCorrect(true);
      setOtp("");
      setToken(result.token);
      setButtonLabel("Gửi");
      setInstruction("Nhập mật khẩu mới của bạn");
    } else {
      setMessage(result.message);
    }
  }

  const handleForgotPassword = async () => {
    const result = await forgotPassword(token, phone, newPassword);
    console.log('handleForgotPassword Result: ', result);

    if (result.status) {
      setResetSuccess(true);
      resetValues();
    } else {
      setMessage(result.message);
      console.error(result.message);
    }
  }

  const handleForgotPasswordButton = () => {
    if (sentOTP) {
      if (isOTPcorrect) {
        handleForgotPassword()
      } else {
        handleVerifyOTP();
      }
    } else {
      handleSendOTP();
    }
  };

  const resetValues = () => {
    setPhone("");
    setOtp("");
    setNewPassword("");
    setSentOTP(false);
    setExpiredOTP(false);
    setTimer(60);
    setIsOTPCorrect(false);
    setMessage('');
  };

  const backToHome = () => {
    setResetSuccess(false);
    navigate('/')
  }

  const resetPasswordInput = () => {
    if (sentOTP) {
      if (isOTPcorrect) {
        return (
          <div style={{ display: 'grid' }}>
            <input
              type="text"
              value={newPassword}
              onChange={(e) => {
                setMessage("");
                setNewPassword(e.currentTarget.value);
              }}
              placeholder="Mật Khẩu Mới"
              required
              className='input-number otp'
            />
            <div className='input-description'>Tối thiểu 6 ký tự</div>
          </div>
        );
      } else {
        return (
          <div style={{ display: 'grid' }}>
            <input
              type="number"
              value={otp}
              onChange={(e) => {
                setMessage("");
                setOtp(e.currentTarget.value);
              }}
              placeholder="OTP"
              required
              className='input-number otp'
            />

            {message && (
              <div className='input-error'>Mã OTP không chính xác.</div>
            )}
          </div>
        );
      }
    } else {
      return (
        <div style={{ position: 'relative', display: 'grid' }}>
          <input
            type="number"
            value={phone}
            onChange={(e) => {
              const inputValue = e.currentTarget.value;
              setMessage("");
              if (inputValue.length <= 10) {
                setPhone(inputValue);
              }
            }}
            placeholder="Số điện thoại"
            required
            className='input-number'
          />
          {message && (
            <div className='input-error'>Số điện thoại không hợp lệ.</div>
          )}
          <FaPhoneAlt className="phone-icon" size={18} color="#06BBF7" />
        </div>
      );
    }
  }

  return (
    <div className="forgot-password-page">
      <div className="forgot-password-container">
        <div style={{ fontWeight: 600 }}>Đặt Lại Mật Khẩu</div>
        <Divider style={{ background: '#5F5F5F', height: '1px' }} />
        {resetSuccess && <img style={{ margin: 'auto' }} src={CheckDecagram} alt="Success" />}
        {resetSuccess && <div>Chúc mừng.<br />Mật khẩu của bạn đã được<br />đặt lại.</div>}

        {/* Modal Instruction */}
        {(!resetSuccess && <div>{instruction}</div>)}

        {/* Modal Inputs */}
        {(!resetSuccess && resetPasswordInput())}

        {/* Resend OTP */}
        {sentOTP && !isOTPcorrect &&
          <div>
            <div className="otp-timer">{formatTime()}</div>
            <div>Chưa nhận được?
              <u
                className={`resend-otp ${expiredOTP ? 'active' : ''}`}
                onClick={() => { expiredOTP && handleSendOTP() }}
              >
                Gửi lại OTP.
              </u>
            </div>
          </div>}

        {/* Modal Buttons: Send OTP, Verify OTP, Set New Password */}
        {!resetSuccess &&
          <Button
            onClick={handleForgotPasswordButton}
            disabled={disableForgotPasswordButton()}
            style={buttonStyles}
            className="otp-button"
          >
            {buttonLabel}
          </Button>}
        {resetSuccess && <Button onClick={backToHome} className="otp-button">Đăng Nhập</Button>}
      </div>
    </div>
  )
};

export default ForgotPassword;