import getAxiosInstance from "./BaseUrl";

export const APICheckAnnouncement = async () => {
  const BaseUrl = await getAxiosInstance();

  try {
    const response = await BaseUrl.get("/player/announcements?device_type=desktop");
    if (response.status === 200) {
      return response.data
    }
  } catch (error) {
    console.error("An error occured: ", error);
  }
}

