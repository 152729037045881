import style from "../../components/HottestSection/HottestSection.module.css";
import { useContext, useState, useEffect } from "react";
import UserContext from "../../helpers/Context/user-context";
import { useNavigate } from "react-router-dom";
import DagaErrorModal from "../HomeComponents/DagaErrorModal";
import { useDagaGame } from "../../helpers/useDagaGame";

import { useQuery } from "react-query";
import SingleGameList from "./SingleGameList/SingleGameList";
import { getGameCategories } from "../../helpers/APIs/GameAPIs";
import MyLoader from "../MyLoader";

export const HottestSection = () => {
  const { transferAllPointsInDaga, showErrorModal, setShowErrorModal } = useDagaGame();

  const [gameCategories, setGameCategories] = useState();
  const [loading, setLoading] = useState(false);
  const [games, setGames] = useState(null);
  const [gameLink, setGameLink] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [activeCategoryId, setActiveCategoryId] = useState("");
  const [loader, setLoader] = useState(false);

  const ctx = useContext(UserContext);
  const navigate = useNavigate();
  const referral = ctx.refCode && ctx.refCode
  const { data, isLoading, error } = useQuery('categories', getGameCategories);

  const hottestGameCategory = async () => {
    setLoader(true);
    try {
      const response = await getGameCategories({ 'category_name': 'Hottest' });

      setGameCategories(response?.games[0]);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      setGameCategories([]);
      console.error('Error fetching categories:', error);
    }
  }

  useEffect(() => {
    hottestGameCategory();
  }, []);

  return (
    <div className={style.wrapper}>
      <div className={style.sectionTitle}>
        <svg xmlns="http://www.w3.org/2000/svg" width="320" height="6" viewBox="0 0 320 6" fill="none">
          <path d="M319.887 3.00002L317 0.113264L314.113 3.00002L317 5.88677L319.887 3.00002ZM317 2.50002L0.5 2.49999L0.5 3.49999L317 3.50002L317 2.50002Z" fill="white" />
        </svg>

        <div className={style.title}>Hot Nhất</div>
        <svg xmlns="http://www.w3.org/2000/svg" width="320" height="6" viewBox="0 0 320 6" fill="none">
          <path d="M0.113251 2.99999L3 5.88674L5.88675 2.99999L3 0.113236L0.113251 2.99999ZM319.5 2.50002L3 2.49999L3 3.49999L319.5 3.50002L319.5 2.50002Z" fill="white" />
        </svg>
      </div>

      {loading && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%'
          }}
        >
          <MyLoader />
        </div>
      )}
      {gameCategories && (
        gameCategories?.game_items && gameCategories?.game_items?.map((game, index) => (
          <div className={style.sectionContent}>
            <div className={style.imageContainer}>
              <div className={style.gameItem}>
                <SingleGameList
                  game={game}
                  gameLink={''}
                  activeCategoryId={activeCategoryId}
                />
              </div>
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default HottestSection;
