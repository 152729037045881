export default function TransferPointsButton({ fillColor }) {
  return (
    <svg width="25" height="25" viewBox="0 0 20 20" fill={fillColor} xmlns="http://www.w3.org/2000/svg">
      <rect width="20" height="20" rx="2" fill="url(#paint0_linear_3526_48869)" />
      <path d="M15.7435 11.2564H14.6666C14.5238 11.2564 14.3868 11.1997 14.2858 11.0987C14.1849 10.9977 14.1281 10.8608 14.1281 10.7179C14.1281 10.5751 14.1849 10.4382 14.2858 10.3372C14.3868 10.2362 14.5238 10.1795 14.6666 10.1795H15.7435C15.9814 10.1791 16.2095 10.0844 16.3777 9.91621C16.5459 9.74799 16.6406 9.51995 16.641 9.28205V4.97436C16.6406 4.73646 16.5459 4.50841 16.3777 4.3402C16.2095 4.17198 15.9814 4.0773 15.7435 4.07692H4.25634C4.01844 4.0773 3.7904 4.17198 3.62218 4.3402C3.45396 4.50841 3.35929 4.73646 3.35891 4.97436V9.28205C3.35929 9.51995 3.45396 9.74799 3.62218 9.91621C3.7904 10.0844 4.01844 10.1791 4.25634 10.1795H5.33326C5.47607 10.1795 5.61303 10.2362 5.71401 10.3372C5.815 10.4382 5.87173 10.5751 5.87173 10.7179C5.87173 10.8608 5.815 10.9977 5.71401 11.0987C5.61303 11.1997 5.47607 11.2564 5.33326 11.2564H4.25634C3.73283 11.256 3.23086 11.0479 2.86068 10.6777C2.4905 10.3075 2.28236 9.80557 2.28198 9.28205V4.97436C2.28236 4.45084 2.4905 3.94888 2.86068 3.5787C3.23086 3.20851 3.73283 3.00038 4.25634 3H15.7435C16.267 3.00038 16.769 3.20851 17.1392 3.5787C17.5094 3.94888 17.7175 4.45084 17.7179 4.97436V9.28205C17.7175 9.80557 17.5094 10.3075 17.1392 10.6777C16.769 11.0479 16.267 11.256 15.7435 11.2564Z" fill="white" />
      <path d="M13.2308 16.9998H6.76928C6.24576 16.9994 5.7438 16.7913 5.37362 16.4211C5.00344 16.0509 4.7953 15.5489 4.79492 15.0254V6.41004C4.79492 6.26723 4.85165 6.13027 4.95263 6.02929C5.05361 5.92831 5.19057 5.87158 5.33338 5.87158H14.6667C14.8095 5.87158 14.9465 5.92831 15.0475 6.02929C15.1484 6.13027 15.2052 6.26723 15.2052 6.41004V15.0254C15.2048 15.5489 14.9967 16.0509 14.6265 16.4211C14.2563 16.7913 13.7543 16.9994 13.2308 16.9998ZM5.87185 6.94851V15.0254C5.87222 15.2633 5.9669 15.4914 6.13512 15.6596C6.30334 15.8278 6.53138 15.9225 6.76928 15.9229H13.2308C13.4687 15.9225 13.6968 15.8278 13.865 15.6596C14.0332 15.4914 14.1279 15.2633 14.1283 15.0254V6.94851H5.87185Z" fill="white" />
      <path d="M9.99992 14.8462C9.9292 14.8464 9.85914 14.8325 9.79382 14.8054C9.7285 14.7783 9.66922 14.7385 9.61941 14.6883L7.46556 12.5344C7.37045 12.4324 7.31867 12.2974 7.32113 12.1579C7.32359 12.0184 7.3801 11.8853 7.47876 11.7866C7.57741 11.688 7.71051 11.6315 7.85001 11.629C7.98951 11.6265 8.12451 11.6783 8.22659 11.7734L9.99992 13.546L11.7733 11.7734C11.8753 11.6783 12.0103 11.6265 12.1498 11.629C12.2893 11.6315 12.4224 11.688 12.5211 11.7866C12.6197 11.8853 12.6763 12.0184 12.6787 12.1579C12.6812 12.2974 12.6294 12.4324 12.5343 12.5344L10.3804 14.6883C10.3306 14.7385 10.2713 14.7783 10.206 14.8054C10.1407 14.8325 10.0706 14.8464 9.99992 14.8462Z" fill="white" />
      <path d="M9.99989 14.8462C9.85708 14.8462 9.72012 14.7895 9.61914 14.6885C9.51816 14.5875 9.46143 14.4506 9.46143 14.3078V9.28211C9.46143 9.1393 9.51816 9.00235 9.61914 8.90136C9.72012 8.80038 9.85708 8.74365 9.99989 8.74365C10.1427 8.74365 10.2797 8.80038 10.3806 8.90136C10.4816 9.00235 10.5383 9.1393 10.5383 9.28211V14.3078C10.5383 14.4506 10.4816 14.5875 10.3806 14.6885C10.2797 14.7895 10.1427 14.8462 9.99989 14.8462Z" fill="white" />
      <path d="M15.3846 6.94851H4.61537C4.47256 6.94851 4.3356 6.89177 4.23462 6.79079C4.13363 6.68981 4.0769 6.55285 4.0769 6.41004C4.0769 6.26723 4.13363 6.13027 4.23462 6.02929C4.3356 5.92831 4.47256 5.87158 4.61537 5.87158H15.3846C15.5274 5.87158 15.6644 5.92831 15.7653 6.02929C15.8663 6.13027 15.9231 6.26723 15.9231 6.41004C15.9231 6.55285 15.8663 6.68981 15.7653 6.79079C15.6644 6.89177 15.5274 6.94851 15.3846 6.94851Z" fill="white" />
      <defs>
        <linearGradient id="paint0_linear_3526_48869" x1="10" y1="0" x2="10" y2="20" gradientUnits="userSpaceOnUse">
          <stop stop-color="#06BBF7" />
          <stop offset="1" stop-color="#17346C" />
        </linearGradient>
      </defs>
    </svg>
  )
}