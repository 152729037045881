import { Popover } from "@mui/material";
import styles from "./WalletModal.module.css";
import { IoMdClose } from "react-icons/io";
import GameListing from "../GameListing/GameListing";

const WalletModal = ({ open, id, anchorEl, handleClose }) => {

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      style={{
        marginTop: "14px",
      }}
    >
      <div className={styles.popoverStyles}>
        <div className={styles.loginHeader}>
          Tất cả các ví
          <IoMdClose
            onClick={() => handleClose()}
            style={{ cursor: "pointer" }}
            size={26}
            color="#00C1FE"
          />
        </div>

        <GameListing></GameListing>
      </div>
    </Popover >
  );
};

export default WalletModal;
