import React, { useState, useEffect } from "react";
import { APIBannerImages } from "../../../helpers/APIs/BannerAPI";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MobileStepper from "@mui/material/MobileStepper";
import Button from "@mui/material/Button";
import arrowRight from "../../../assets/HomeImages/ArrowRight.webp";
import arrowLeft from "../../../assets/HomeImages/ArrowLeft.webp";
import SwipeableViews from "react-swipeable-views-react-18-fix";
import { autoPlay } from "react-swipeable-views-utils";
import styles from "./TopBanner.module.css";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

export default function TopBanner() {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const [images, setImages] = useState([]);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await APIBannerImages();
        setImages(response)
      } catch (error) {
        console.error('Error fetching image: ', error);
      }
    }
    fetchImages();
  }, []);

  const handleImageClick = async (imageUrl) => {
    try {
      const response = await APIBannerImages();
      if (response.url != null) {
        window.open(imageUrl, '_blank');
      } else {
        return null;
      }
    } catch (error) {
      console.error('There is an error fetching the images: ', error);
    }
  };

  const handleNext = () => {
    if (activeStep === images.length - 1) {
      setActiveStep(0);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    if (activeStep === 0) {
      setActiveStep(images.length - 1);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <Box sx={{ width: "100%", flexGrow: 1 }}>
      <AutoPlaySwipeableViews
        axis={theme.direction === "ltr" ? "x" : "x-reverse"}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {images?.length ?
          images?.map((image) => (
            <div
              style={{ cursor: 'pointer' }}
              key={image.id}
              onClick={() => handleImageClick(image.url)}
            >
              <Box
                component="img"
                sx={{
                  height: "auto",
                  display: "block",
                  overflow: "hidden",
                  width: "100%",
                }}
                src={image.image_url}
                alt={image.description}
              />
            </div>
          ))
          :
          <div className={styles.errorMessage}>No images available</div>
        }
      </AutoPlaySwipeableViews>
      <MobileStepper
        sx={{
          ".MuiMobileStepper-dots": {
            position: "relative",
            marginTop: "-70px ",
          },
          ".MuiMobileStepper-dotActive": {
            backgroundColor: "white",
          },
        }}
        style={{ padding: "0 8%" }}
        variant="dots"
        steps={images?.length ? images?.length : ""}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            // disabled={activeStep === maxSteps - 1}
            style={{ marginTop: "-40%", cursor: "pointer" }}
          >
            <img src={arrowRight} alt="right" />
          </Button>
        }
        backButton={
          <Button
            size="small"
            onClick={handleBack}
            // disabled={activeStep === 0}
            style={{ marginTop: "-40%", cursor: "pointer" }}
          >
            <img src={arrowLeft} alt="left" />
          </Button>
        }
      />
    </Box>
  );
}
