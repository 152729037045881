import AllWalletProfile from "../../components/AllWalletProfile/AllWalletProfile";
import styles from "./AllWallet.module.css";

const AllWallet = () => {
  return (
    <div className={styles.allWalletOverlay}>
      <AllWalletProfile />
    </div>
  );
};

export default AllWallet;
